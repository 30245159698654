import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EntityMap } from '@shared/types';
import { Apollo } from 'apollo-angular';
import { catchError, of, tap } from 'rxjs';
import { GET_WORKFLOW, GET_WORKFLOW_SCREEN } from '../../shared/gql-shared-queries';
import { JsonSchemaService } from '../services/json-schema.service';
import { Step } from './models/step.model';
import { WorkflowScreen } from './models/workflow-screen.model';
import { Workflow } from './models/workflow.model';
import {
  FetchInjectedScreen,
  FetchScreenById,
  FetchServiceView,
  FetchWorkflow,
  ResetScreens,
  ResetWorkflow,
  SetInitialStep,
  SetNextStep,
  SetStep,
} from './workflow.actions';

export class WorkflowStateModel {
  workflow: Workflow;
  currentStep: Step;
  currentScreen: WorkflowScreen;
  injectedScreen: WorkflowScreen;
  tempView: WorkflowScreen;
}

const defaults: WorkflowStateModel = {
  workflow: null,
  currentStep: null,
  currentScreen: null,
  injectedScreen: null,
  tempView: null,
};

//TODO: move the store to appropriate place. added this store in this module for testing purpose

@State<WorkflowStateModel>({
  name: 'workflow',
  defaults,
})
@Injectable()
export class WorkflowState {
  constructor(
    private readonly gqlService: Apollo,
    private readonly jsonSchemaService: JsonSchemaService
  ) {}

  @Selector()
  static getWorkflow(state: WorkflowStateModel): Workflow {
    return state.workflow;
  }

  @Selector()
  static getCurrentScreen(state: WorkflowStateModel): WorkflowScreen {
    return state?.currentScreen;
  }

  @Selector()
  static getInjectedScreen(state: WorkflowStateModel): WorkflowScreen {
    return state?.injectedScreen;
  }

  @Selector()
  static getServiceView(state: WorkflowStateModel): WorkflowScreen {
    return state?.tempView;
  }

  @Selector()
  static getCurrentStep(state: WorkflowStateModel): Step {
    return state.currentStep;
  }

  @Selector()
  static getDefaultServiceConfigs(state: WorkflowStateModel): EntityMap<string, string[]> {
    return state.workflow?.servicesConfig as unknown as EntityMap<string, string[]>;
  }

  @Action(FetchWorkflow, { cancelUncompleted: true })
  fetchWorkflow({ patchState, getState }: StateContext<WorkflowStateModel>, { workflowId }: FetchWorkflow) {
    if (getState()?.workflow) {
      return of();
    }
    return this.gqlService
      .query<Workflow>({
        query: GET_WORKFLOW,
        variables: { workflowId },
      })
      .pipe(
        tap(result => {
          const workflowData = result?.data['workflow'];
          const workflow: Workflow = {
            _id: workflowData._id,
            name: workflowData.name,
            workflowId: workflowData.workflowId,
            steps: workflowData.steps,
            servicesConfig: workflowData?.servicesConfig,
          };
          patchState({
            workflow,
          });
        }),
        catchError(async error => console.error('Error fetching workflow', error))
      );
  }

  @Action(FetchInjectedScreen)
  fetchInjectableScreen({ patchState }: StateContext<WorkflowStateModel>, { screenId }: FetchInjectedScreen) {
    // //TODO: remove after testing
    // return this.jsonSchemaService.loadValues('create_order').pipe(
    //   tap(res => {
    //     patchState({
    //       injectedScreen: res,
    //     });
    //   })
    // );
    if (!screenId) {
      return of();
    }
    patchState({
      injectedScreen: null,
    });
    return this.gqlService
      .query<WorkflowScreen>({
        query: GET_WORKFLOW_SCREEN,
        variables: { screenId },
      })
      .pipe(
        tap(result => {
          const screen = result?.data['findWorkflowScreenById'];
          patchState({
            injectedScreen: screen,
          });
        }),
        catchError(async error => console.error('Error fetching screen by id', error))
      );
  }

  @Action(FetchServiceView)
  fetchServiceView({ patchState }: StateContext<WorkflowStateModel>, { screenId }: FetchServiceView) {
    // return this.jsonSchemaService.loadValues('air-freight').pipe(
    //   tap(res => {
    //     patchState({
    //       tempView: res,
    //     });
    //   })
    // );

    if (!screenId) {
      return of();
    }

    return this.gqlService
      .query<WorkflowScreen>({
        query: GET_WORKFLOW_SCREEN,
        variables: { screenId },
      })
      .pipe(
        tap(result => {
          const screen = result?.data['findWorkflowScreenById'];
          patchState({
            tempView: screen,
          });
        }),
        catchError(async error => console.error('Error fetching screen by id', error))
      );
  }

  @Action(FetchScreenById)
  fetchScreenById({ patchState }: StateContext<WorkflowStateModel>, { screenId }: FetchScreenById) {
    // return this.jsonSchemaService.loadValues('assigned_services').pipe(
    //   tap(res => {
    //     patchState({
    //       currentScreen: res,
    //     });
    //   })
    // );

    if (!screenId) {
      return of();
    }
    return this.gqlService
      .query<WorkflowScreen>({
        query: GET_WORKFLOW_SCREEN,
        variables: { screenId },
      })
      .pipe(
        tap(result => {
          const screen = result?.data['findWorkflowScreenById'];
          patchState({
            currentScreen: screen,
          });
        }),
        catchError(async error => console.error('Error fetching screen by id', error))
      );
  }

  @Action(ResetScreens)
  resetScreens({ patchState }: StateContext<WorkflowStateModel>) {
    patchState({
      currentScreen: null,
    });
  }

  @Action(ResetWorkflow)
  resetWorkflow({ patchState }: StateContext<WorkflowStateModel>) {
    patchState(defaults);
  }

  @Action(SetInitialStep)
  setInitialStep({ patchState, getState }: StateContext<WorkflowStateModel>) {
    const workflow = getState().workflow;
    patchState({
      currentStep: workflow.steps[0],
    });
  }

  @Action(SetStep)
  setStep({ patchState, getState }: StateContext<WorkflowStateModel>, { stepId }: SetStep) {
    patchState({
      currentStep: getState().workflow.steps.find(step => step.id === stepId),
    });
  }

  @Action(SetNextStep)
  setNextStep({ patchState, getState }: StateContext<WorkflowStateModel>, { condition }: SetNextStep) {
    const currentStep = getState().currentStep;

    if (!condition || Object.keys(condition).length === 0) {
      // If condition is null, undefined, or an empty object
      const unconditionalStep = currentStep.next.find(nextStep => !nextStep.conditionKey);
      if (unconditionalStep) {
        const nextStepId = unconditionalStep.nextStep;
        const nextStep = getState().workflow.steps.find(step => step.id === nextStepId);
        patchState({ currentStep: nextStep });
      }
      return;
    }

    const nextCondition = currentStep.next.find(
      nextStep => nextStep.conditionKey && condition[nextStep.conditionKey] === nextStep.conditionValue
    );

    if (nextCondition) {
      const nextStepId = nextCondition.nextStep;
      const nextStep = getState().workflow.steps.find(step => step.id === nextStepId);
      patchState({ currentStep: nextStep });
    }
  }
}
